import { createContext, useState } from 'react';

// Create a context
export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState({});

  return <AccountContext.Provider value={{ account, setAccount }}>{children}</AccountContext.Provider>;
};
