import React from 'react';

import { Typography, Button, Box, Grid, TextField } from '@mui/material';

import { boxShadowStyles } from '../../jsxStyles';

const fieldsStyles = {
  mt: '20px',
  mx: { xs: 'auto', sm: 'inherit' },
  width: '100%',
};

const OnboardingStep3 = ({
  edifierToEditData,
  register,
  errors,
  setActiveStep,
  formOtherDefaultValues,

  isOnlyEdifier,
}) => (
  <Box
    sx={{
      ...boxShadowStyles,
    }}
  >
    <Typography
      sx={{
        m: '26px 0 20px 0',
        fontSize: '24px',
        color: '#000',
        fontFamily: "'Noto Sans JP'",
        lineHeight: '30px',
      }}
      variant="form"
    >
      Your Bio, 'Why', & Words of Encouragement
    </Typography>

    <Grid container spacing="20px" sx={{ ...fieldsStyles }}>
      <span style={{ fontSize: '12px' }}>
        {isOnlyEdifier
          ? 'Brief Bio: In 2-3 sentences, give a brief overview of who you are as a prospective teacher mentor.'
          : "In a sentence or less, what is your goal for this year in Edifying Teachers? (This may change over time and you will discuss it with your Edifier in your initial conversations, but we'd love to get a sense of where you are today)"}
      </span>
      {!edifierToEditData || 'interestBio' in formOtherDefaultValues === false ? (
        <TextField
          multiline
          // id={field/.name}
          id="interestBio"
          name="interestBio"
          fullWidth
          rows={5}
          // {...register('bio')}

          {...register('interestBio', { required: 'Bio/Goals are required' })}
          error={errors && !!errors?.interestBio}
          helperText={errors?.interestBio?.message}
          type="text"
          sx={{ '& .MuiFormHelperText-root': { ml: '0' } }}
        />
      ) : (
        'interestBio' in formOtherDefaultValues && (
          <TextField
            multiline
            // id={field/.name}
            id="interestBio"
            name="interestBio"
            // label={
            //   isOnlyEdifier
            //     ? 'Brief Bio: In 2-3 sentences, give a brief overview of who you are as a prospective teacher mentor.'
            //     : "In a sentence or less, what if your goal for this year in Edifying Teachers? (This may change over time and you will discuss it with your Edifier in your initial conversations, but we'd love to get a sense of where you are today)"
            // }
            fullWidth
            rows={5}
            {...register('interestBio', { required: 'Bio/Goals are required' })}
            error={errors && !!errors?.interestBio}
            helperText={errors?.interestBio?.message}
            type="text"
            defaultValue={formOtherDefaultValues?.interestBio}
            sx={{ '& .MuiFormHelperText-root': { ml: '0' } }}

            // value="dijsaj"
          />
        )
      )}
    </Grid>

    <Grid container spacing="20px" sx={{ ...fieldsStyles }}>
      <span style={{ fontSize: '12px' }}>
        {isOnlyEdifier
          ? "Tell us a bit about your 'why' - what motivates your interest in mentoring new teachers?"
          : "Tell us a bit about your 'why' - what motivates your interest in teaching?"}
      </span>
      {!edifierToEditData || 'whatMotivates' in formOtherDefaultValues === false ? (
        <TextField
          // multiline
          // id={field/.name}
          id="whatMotivates"
          name="whatMotivates"
          fullWidth
          rows={5}
          // {...register('bio')}

          {...register('whatMotivates', { required: 'Motivation/Interest is Required' })}
          error={errors && !!errors?.whatMotivates}
          helperText={errors?.whatMotivates?.message}
          type="text"
          sx={{ '& .MuiFormHelperText-root': { ml: '0' } }}
        />
      ) : (
        formOtherDefaultValues?.whatMotivates && (
          <TextField
            // multiline
            // id={field/.name}
            id="whatMotivates"
            name="whatMotivates"
            // label={
            //   isOnlyEdifier
            //     ? "Tell us a bit about your 'why' - what motivates your interest in mentoring new teachers of color?"
            //     : "Tell us a bit about your 'why' - what motivates your interest in teaching?"
            // }
            fullWidth
            rows={5}
            {...register('whatMotivates', { required: 'Motivation/Interest is Required' })}
            error={errors && !!errors?.whatMotivates}
            helperText={errors?.whatMotivates?.message} // error={errors && !!errors[field.name]}
            // helperText={errors[field.name]?.message}
            type="text"
            defaultValue={formOtherDefaultValues?.whatMotivates}
            sx={{ '& .MuiFormHelperText-root': { ml: '0' } }}

            // value="dijsaj"
          />
        )
      )}
    </Grid>

    <Grid container spacing="20px" sx={{ ...fieldsStyles }}>
      <span style={{ fontSize: '12px' }}>
        {isOnlyEdifier
          ? "We know each person's challenges vary, but what are a few general words of advice and encouragement you would give to a new teacher ?"
          : "We know each person's challenges vary, but what are the key areas that you would like to concentrate most on this year with your Edifier mentor?"}
      </span>
      {!edifierToEditData || 'encouragementAdvice' in formOtherDefaultValues === false ? (
        <TextField
          // multiline
          // id={field/.name}
          id="encouragementAdvice"
          name="encouragementAdvice"
          fullWidth
          rows={5}
          // {...register('bio')}

          {...register('encouragementAdvice', {
            required: `${isOnlyEdifier ? 'Encouragement Advice is' : 'Key Areas are'} required`,
          })}
          error={errors && !!errors?.encouragementAdvice}
          helperText={errors?.encouragementAdvice?.message}
          type="text"
          sx={{ '& .MuiFormHelperText-root': { ml: '0' } }}
        />
      ) : (
        formOtherDefaultValues?.encouragementAdvice && (
          <TextField
            id="encouragementAdvice"
            name="encouragementAdvice"
            fullWidth
            rows={5}
            {...register('encouragementAdvice', {
              required: `${isOnlyEdifier ? 'Encouragement Advice is' : 'Key Areas are'} required`,
            })}
            error={errors && !!errors?.encouragementAdvice}
            helperText={errors?.encouragementAdvice?.message}
            // error={errors && !!errors[field.name]}
            // helperText={errors[field.name]?.message}
            type="text"
            defaultValue={formOtherDefaultValues?.encouragementAdvice}
            sx={{ '& .MuiFormHelperText-root': { ml: '0' } }}

            // value="dijsaj"
          />
        )
      )}
    </Grid>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
      <Button
        sx={{
          background: '#6B9D98',
          width: { xs: '100px', sm: '164px' },
          height: { xs: '35px', sm: '48px' },
          color: 'white',
        }}
        onClick={() => setActiveStep(1)}
      >
        Go Back
      </Button>
      <Button
        sx={{
          background: '#6B9D98 !important',
          width: { xs: '100px', sm: '164px' },
          height: { xs: '35px', sm: '48px' },
          color: 'white',
        }}
        type="submit"
        id="index_3_3"
      >
        Next
      </Button>
    </Box>
  </Box>
);

export default OnboardingStep3;
