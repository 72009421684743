/*eslint-disable */
import * as React from 'react';
// import PropTypes from 'prop-types';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';

// import Table from '@mui/material/Sty';
import { Marker } from 'react-mark.js';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableFooter from '@mui/material/TableFooter';
// import TablePagination from '@mui/material/TablePagination';

import { LoadingButton } from '@mui/lab';

import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';

// import IconButton from '@mui/material/IconButton';
// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import LastPageIcon from '@mui/icons-material/LastPage';
import { TableHead } from '@mui/material';
import { mainTitlesStyles } from '../../jsxStyles';
import TableColumn from './tableCell';
import { fieldsNotToDisplay, findAnEdifierData } from '../../_mock';

// function TablePaginationActions(props) {
//   const theme = useTheme();
//   const { count, page, rowsPerPage, onPageChange } = props;

//   const handleFirstPageButtonClick = (event) => {
//     onPageChange(event, 0);
//   };

//   const handleBackButtonClick = (event) => {
//     onPageChange(event, page - 1);
//   };

//   const handleNextButtonClick = (event) => {
//     onPageChange(event, page + 1);
//   };

//   const handleLastPageButtonClick = (event) => {
//     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };

//   return (
//     <Box sx={{ flexShrink: 0, ml: 2.5 }}>
//       <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
//         {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
//       </IconButton>
//       <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
//         {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//       </IconButton>
//       <IconButton
//         onClick={handleNextButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="next page"
//       >
//         {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//       </IconButton>
//       <IconButton
//         onClick={handleLastPageButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="last page"
//       >
//         {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
//       </IconButton>
//     </Box>
//   );
// }

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Jelly Bean', 375, 0.0),
  createData('KitKat', 518, 26.0),
  createData('Lollipop', 392, 0.2),
  createData('Marshmallow', 318, 0),
  createData('Nougat', 360, 19.0),
  createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export default function SimpleTable({
  tableData,
  setItemToDeleteId,
  setOpen,
  isSchools,
  setEditData,
  setEditOpen,
  userRole,
  searchedValue,
  manageAvailability,
  isChangingAvailability,
  setAvailabilityPayload,
  availabilityPayload,
  pairedDisplay,
  isEndingTraining,
  setTrainingEndingPayload,
  setCurrentResponse,
  setPairingEndingPayload,
  responses,
  isEndingPairing,
}) {
  const includesPartnerAffiliation = JSON.stringify(tableData).includes('partnerSchool');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  return (
    <TableContainer component={Paper} sx={{ mt: '33px' }}>
      {tableData?.length > 0 ? (
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{ width: '100%' }}>
              <TableCell />

              <TableCell />

              {tableData?.length &&
                Object.keys(tableData[0] != null ? tableData[0] : [])?.map(
                  (keyName, index) =>
                    fieldsNotToDisplay.includes(keyName) === false &&
                    typeof tableData[0][keyName] !== 'object' && (
                      <TableCell
                        key={index}
                        sx={{ borderBottomLeftRadius: '10px', color: 'black', textTransform: 'capitalize' }}
                      >
                        
                        {isSchools && keyName === 'name' ? 'PartnerShip Name' : keyName}
                      </TableCell>
                    )
                )}

              {includesPartnerAffiliation && (
                <TableCell sx={{ borderBottomLeftRadius: '10px', color: 'black', textTransform: 'capitalize' }}>
                  Partner Affiliation
                </TableCell>
              )}

              <TableCell />

              <TableCell />

              {/* <StyledTableCell sx={{ borderBottomRightRadius: '10px' }}>Summary</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {(rowsPerPage > 0 ? tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows)?.map( */}

            {tableData?.map(
              (row, index) =>
                row != null &&
                row && (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'grid' }}>
                        {row?.image ? (
                          <Box
                            component="img"
                            src={
                              isSchools
                                ? `${row?.image}?${row?.updatedAt}`
                                : row?.image
                                  ? row?.image
                                  : findAnEdifierData[0]?.img
                            }
                            sx={{ width: '40px', height: '40px', borderRadius: '50%' }}
                          />
                        ) : (
                          <AccountCircleIcon sx={{ height: '40px', width: '40px', color: 'green' }} />
                        )}
                      </Box>
                    </TableCell>

                    {Object.keys(row)?.map(
                      (keyName, index) =>
                        fieldsNotToDisplay.includes(keyName) === false &&
                        typeof row[keyName] !== 'object' && (
                          <TableColumn
                            key={index}
                            index={index}
                            row={row}
                            keyName={keyName}
                            searchedValue={searchedValue}
                            Marker={Marker}
                            manageAvailability={manageAvailability}
                          />
                        )
                    )}

                    {includesPartnerAffiliation && <TableCell> {row?.info?.partnerSchool || 'NA'}</TableCell>}

                    {manageAvailability && (
                      <TableColumn
                        toggleField
                        setAvailabilityPayload={setAvailabilityPayload}
                        row={row}
                        isChangingAvailability={isChangingAvailability}
                        availabilityPayload={availabilityPayload}
                      />
                    )}
                    {userRole === 'admin' && !pairedDisplay && (
                      <TableCell>
                        <Box
                          component="img"
                          src="/assets/tableEditIcon.svg"
                          sx={{ cursor: 'pointer', minWidth: '25px', minHeight: '25px' }}
                          onClick={() => {
                            setEditData(row);
                            setEditOpen(true);
                          }}
                        />
                      </TableCell>
                    )}

                    {userRole === 'admin' && pairedDisplay && (
                      <TableCell>
                        <Box sx={{ display: 'flex', columnGap: '10px' }}>
                          <LoadingButton
                            variant="contained"
                            loading={isEndingTraining}
                            disabled={row?.lastSession || isEndingPairing}
                            color="main"
                            sx={{ height: 'fit-content', my: 'auto', width: '150px' }}
                            onClick={() => setTrainingEndingPayload({ id: row?._id })}
                          >
                            {row?.lastSession ? 'Training Ended' : 'End Training'}
                          </LoadingButton>

                          <LoadingButton
                            variant="contained"
                            loading={isEndingPairing}
                            // disabled={row?.lastSession}
                            color="main"
                            sx={{ height: 'fit-content', my: 'auto', width: 'max-content' }}
                            onClick={() => setPairingEndingPayload({ ...row?.usersInPair })}
                          >
                            Unpair{' '}
                          </LoadingButton>
                        </Box>
                      </TableCell>
                    )}

                    {responses && (
                      <LoadingButton
                        variant="contained"
                        color="main"
                        onClick={() => setCurrentResponse(row?.surveyData)}
                      >
                        {' '}
                        View Response
                      </LoadingButton>
                    )}
                    {userRole === 'admin' && !pairedDisplay && (
                      <TableCell>
                        <Box
                          component="img"
                          src="/assets/tableDeleteIcon.svg"
                          sx={{ cursor: 'pointer', minWidth: '25px', minHeight: '25px' }}
                          onClick={() => {
                            setItemToDeleteId(row._id);
                            setOpen(true);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="mainTitles" sx={{ ...mainTitlesStyles, textAlign: 'center', py: '20px' }}>
          No Records Found
        </Typography>
      )}
    </TableContainer>
  );
}
