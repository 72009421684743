/* eslint-disable */
import { styled } from '@mui/material/styles';
import { Card, Typography, CircularProgress, Button, Box } from '@mui/material';
// utils

// components

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------
export default function AppWidgetSummary({
  title,
  total,
  icon,
  loading,
  goals,
  goalsModalData,
  setGoalsModalData,
  updatedGoalsPayload,
  setUpdatedGoalsPayload,
  userGoals,
  isTeacher,
  isSubmittingGoals,
  color = 'primary',
  searchFromRecord,
  sx,
  searchedValue,
  setSearchedValue,
  detailedInformation,
  isCompleted,
  msCsRepot,
  navigate,
  isSurveyDetails,
  ...other
}) {
  return (
    <Card
      sx={{
        textAlign: 'center',
        background: '#FFF',
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
        }}
      >
        {/* <Iconify icon={icon} width={24} height={24} /> */}
        {icon}{' '}
      </StyledIcon>

      {!msCsRepot && <Typography sx={{ fontSize: '30px', fontWeight: 600 }}>{total}</Typography>}

      {loading && (
        <CircularProgress
          sx={{ '&.MuiCircularProgress-root': { height: '20px !important', width: '20px !important' } }}
        />
      )}
      <Typography variant="mainTitles" sx={{ fontSize: { xs: '20px', sm: '18px', md: '20px' }, color: 'text.titles' }}>
        {title}
      </Typography>

      {msCsRepot &&
        Object.keys(total)?.map((key, index) => (
          <Typography key={index} variant="h5">
            {key}:<span style={{ fontWeight: 'normal', margin: '0 0 0 20px' }}>{total[key]}</span>
          </Typography>
        ))}

      {detailedInformation && total !== 0 && (
        <Button
          sx={{ fontSize: '16px', pr: '20px', color: 'black', fontWeight: '300' }}
          onClick={() =>
            isSurveyDetails
              ? navigate('/dashboard/recorded-responses')
              : setGoalsModalData({
                  display: true,
                  goals,
                  isCompleted,
                  // edifierName: goals[key]?.name,
                })
          }
        >
          See Details
          <Box component="img" src="/assets/dashboard/rightArrow.svg" sx={{ ml: '10px' }} />
        </Button>
      )}

      {/* {!loading && goals && (
        <Box sx={{ textAlign: 'left', mt: '20px' }}>
          {Object.keys(goals)?.map(
            (key, index) => (
              // index <= 2 &&
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} key={index}>
                <Typography
                  sx={{
                    color: 'text.titles',
                    fontSize: '13px',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {goals[key]?.name}
                  <span style={{ textTransform: 'lowercase' }}>'s Goals</span>
                </Typography>


              </Box>
            )
            // goalObject?.goals &&

            // goalObject?.goals.map((goalsSub, subIndex) => (
            //   <li key={`${index}${subIndex}`} style={{ height: '35px', position: 'relative' }}>
            //     {goalsSub?.title}{' '}
            //     <Checkbox
            //       {...label}
            //       sx={{ position: 'absolute', right: 0, '& .MuiSvgIcon-root': { color: 'black' } }}
            //     />
            //   </li>
            // ))
          )}
        </Box>
      )} */}
    </Card>
  );
}
