import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

export default function LimitTags({ placeholder, setGoals, errors, setValue }) {
  const handleChange = (event, value) => {
    setValue('goals', value);
    setGoals([...value]);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        onChange={handleChange}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Box component="span" key={index}>
              <Chip label={option} {...getTagProps({ index })} />
              {index < value.length - 1 && ','} {/* Add a comma between the tags */}
            </Box>
          ))
        }
        renderInput={(params) => (
          <>
            <TextField
              sx={{
                '& .MuiButtonBase-root': { background: 'white !important' },
              }}
              {...params}
              variant="filled"
              label={placeholder}
            />
          </>
        )}
        error={(errors && !!errors?.goals).toString()} // Convert boolean to string
      />
      {errors && !!errors?.goals && (
        <Typography variant="validation" sx={{ fontSize: '15px', position: 'absolute' }}>
          {errors?.goals?.message}
        </Typography>
      )}
    </div>
  );
}
